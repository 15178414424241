<template>
  <div class="text-right box">
    <p>
      The event has ended.
    </p>
    <!-- <div class="dflexs head">
            <img class="returns" @click='returnBtn' src="../../assets/guild/returnimg.png" alt="">
            <div class="pagetitle">{{ $t('Withdrawal') }}</div>
        </div> -->
    <!-- <div class="text">
      <p>{{ $t("list_Rules.txt1") }}</p>
      <p>{{ $t("list_Rules.txt2") }}</p>
      <p>{{ $t("list_Rules.txt3") }}</p>
      <p>{{ $t("list_Rules.txt4") }}</p>
      <p>{{ $t("list_Rules.txt5") }}</p>
      <p>{{ $t("list_Rules.txt6") }}</p>
    </div>
    <table border="1" cellspacing="0">
      <tr>
        <td align="center" colspan="7" height="30" class="title2">
          {{ $t("list_Rules.title2") }}
        </td>
      </tr>
      <tr>
        <td width="50" class="pl3">LEVEL</td>
        <td width="60" height="30" class="td_img pl3">
          <span>MIN</span>
          <img width="20px" src="../assets/layla_zuan.png" alt="" />
        </td>
        <td align="center">TOP1</td>
        <td align="center">TOP2</td>
        <td align="center">TOP3</td>
        <td align="center">TOP4</td>
        <td align="center">TOP5</td>
      </tr>
      <tr>
        <td class="pl3">Lv.0</td>
        <td class="pl3">0</td>
        <td align="center">0%</td>
        <td align="center">0%</td>
        <td align="center">0%</td>
        <td align="center">0%</td>
        <td align="center">0%</td>
      </tr>
      <tr>
        <td class="pl3">Lv.1</td>
        <td class="pl3">3 M</td>
        <td align="center">5%</td>
        <td align="center">4.5%</td>
        <td align="center">4.0%</td>
        <td align="center">3.5%</td>
        <td align="center">3%</td>
      </tr>
      <tr>
        <td class="pl3">Lv.2</td>
        <td class="pl3">6 M</td>
        <td align="center">10%</td>
        <td align="center">9%</td>
        <td align="center">8%</td>
        <td align="center">7%</td>
        <td align="center">6%</td>
      </tr>
      <tr>
        <td class="pl3">Lv.3</td>
        <td class="pl3">15 M</td>
        <td align="center">15%</td>
        <td align="center">14%</td>
        <td align="center">13%</td>
        <td align="center">12%</td>
        <td align="center">11%</td>
      </tr>
      <tr>
        <td class="pl3">Lv.4</td>
        <td class="pl3">30 M</td>
        <td align="center">20%</td>
        <td align="center">19%</td>
        <td align="center">18%</td>
        <td align="center">17%</td>
        <td align="center">16%</td>
      </tr>
      <tr>
        <td class="pl3">Lv.5</td>
        <td class="pl3">45 M</td>
        <td align="center">25%</td>
        <td align="center">24%</td>
        <td align="center">23%</td>
        <td align="center">22%</td>
        <td align="center">21%</td>
      </tr>
      <tr>
        <td class="pl3">Lv.6</td>
        <td class="pl3">50 M</td>
        <td align="center">30%</td>
        <td align="center">29%</td>
        <td align="center">28%</td>
        <td align="center">27%</td>
        <td align="center">26%</td>
      </tr>
      <tr>
        <td class="pl3">Lv.7</td>
        <td class="pl3">100 M</td>
        <td align="center">35%</td>
        <td align="center">34%</td>
        <td align="center">33%</td>
        <td align="center">32%</td>
        <td align="center">31%</td>
      </tr>
      <tr>
        <td class="pl3">Lv.8</td>
        <td class="pl3">200 M</td>
        <td align="center">40%</td>
        <td align="center">39%</td>
        <td align="center">38%</td>
        <td align="center">37%</td>
        <td align="center">36%</td>
      </tr>
    </table> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo: [],
    };
  },
  created() {
    this.$completes.complete("getUserInfo");
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };
  },
  mounted() {},
  methods: {
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      let langs = this.userInfo.lang.substring(0, 2);
      let lang = this.userInfo.lang;
      if (langs == "zh") {
        if (lang == "zh_TW" || lang == "zh_HK") {
          this.$i18n.locale = "tc";
        } else {
          this.$i18n.locale = "zh";
        }
      } else if (langs == "en") {
        this.$i18n.locale = "en";
      } else if (langs == "ar") {
        this.$i18n.locale = "ar";
      } else if (langs == "tr") {
        this.$i18n.locale = "tr";
      } else if (langs == "es") {
        this.$i18n.locale = "es";
      } else if (langs == "pt") {
        this.$i18n.locale = "pt";
      } else {
        this.$i18n.locale = "en";
      }
      document.title = this.$t("list_Rules.title");
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

.box {
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.box p{
  text-align: center;
  margin-top: 200px;
  font-size: 32px;
}

html[lang="ar"] .text p {
  direction: rtl;
}
html[lang="ar"] .title2 {
  direction: rtl;
}

.text {
  flex: 1;
  padding: 20px 18px;
}

.text p {
  line-height: 30px;
  margin-top: 20px;
}
table {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
}
.td_img {
  line-height: 30px;
}
.td_img img {
  vertical-align: middle;
}
.pl3 {
  padding-left: 3px;
}
</style>
